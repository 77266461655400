import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import {
  Order_By,
  useGetSpoofingReportsLazyQuery,
  useUpdateSpoofingReportsMutation,
  useGetUsersQuery,
} from '../../generated/graphql'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { IoMdPerson } from 'react-icons/io'
import { getSortedAlertAssigneesWithUnassigned } from '@/utils/enforcement_utils'
import { getReportsWhereClauseFromExternalIds, parseExternalId } from '@/hooks/queries'
import { ReportChangeEventSource } from '@/generated/enums'
import DoppelModal from '../detailed_view/doppel_modal'

export default function BulkAssignAlertsModal({
  isOpen,
  onClose,
  refreshFunc,
  selectedRows = null,
}) {
  const [notes, setNotes] = useState('')

  const [selectedAssignee, setSelectedAssignee] = useState(null)

  const [alertIds, setAlertIds] = useState('')

  const toast = useToast()

  const firstField = useRef()

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation({
    onCompleted: () => {
      refreshFunc()
      onClose()
      toast({
        title:
          selectedAssignee.email !== 'Unassigned'
            ? 'Successfully assigned alerts to ' + selectedAssignee.email
            : 'Successfully unassigned Alerts',
        status: 'success',
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: 'Error assigning alerts',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    },
  })

  const [getAlertByIds] = useGetSpoofingReportsLazyQuery()

  const { data: assigneeData } = useGetUsersQuery({
    variables: {
      usersWhere: { is_internal: { _eq: true } },
    },
  })

  const alertAssignees = assigneeData?.users || []

  const sortedAssignees = getSortedAlertAssigneesWithUnassigned(alertAssignees)

  const updateAlertFromExternalIds = async () => {
    try {
      const alertExternalIds = alertIds.split('\n').filter((url) => url.trim() !== '')
      const firstExternalId = parseExternalId(alertExternalIds[0])
      if (
        !alertExternalIds.every((externalId) =>
          externalId.startsWith(firstExternalId.orgAbbr),
        )
      ) {
        throw new Error('All alerts need to belong to the same organization')
      }

      const whereClause = getReportsWhereClauseFromExternalIds(alertExternalIds)

      if (
        !whereClause ||
        !whereClause?.external_id ||
        !whereClause?.organization?.abbr_name
      ) {
        throw new Error(
          "Invalid alert ID provided, please make sure it's in the format of TET-1234.",
        )
      }

      const { data } = await getAlertByIds({
        variables: {
          spoofingReportsWhere: whereClause,
          orderBy: [{ created_at: Order_By.Asc }],
        },
      })

      if (data && data.spoofing_reports.length > 0) {
        const alertIds = data.spoofing_reports.map((report) => report.id)
        await updateSpoofingReportsMutation({
          variables: {
            input: {
              report_ids: alertIds,
              update_source: ReportChangeEventSource.UI,
              internal_assignee_id: selectedAssignee.id,
              notes: notes || null,
              is_internal: true,
            },
          },
        })
      } else {
        toast({
          title: 'Error',
          description: 'No matching alerts found for the provided external IDs',
          status: 'error',
          isClosable: true,
        })
        return
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
      return
    }
  }

  const updateAlert = async () => {
    if (!selectedRows) {
      updateAlertFromExternalIds()
    } else {
      const selectedAlertIds = selectedRows.map((row) => row.original.id)
      await updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: selectedAlertIds,
            update_source: ReportChangeEventSource.UI,
            internal_assignee_id: selectedAssignee.id,
            notes: notes || null,
            is_internal: true,
          },
        },
      })
    }
  }

  const reset = () => {
    setNotes('')
    setSelectedAssignee(null)
    setAlertIds('')
  }

  const renderModalBody = () => {
    return (
      <Stack spacing="24px">
        {!selectedRows && (
          <FormControl isRequired>
            <FormLabel>{'Alert IDs (all from 1 organization)'} </FormLabel>

            <Textarea
              minHeight="200px"
              onChange={(e) => setAlertIds(e.target.value)}
              placeholder={'TET-1234\nTET-4567\nTET-8901'}
              ref={firstField}
              value={alertIds}
            />

            <FormHelperText>
              New line separated list of Alert IDs to assign (Max {100})
            </FormHelperText>
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Assignee</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Assignee'}
            icon={<IoMdPerson size="18" />}
            isMultiSelect={false}
            items={
              sortedAssignees ? sortedAssignees.map((assignee) => assignee.email) : []
            }
            placeholder={'Search Assignees...'}
            selectedItems={[selectedAssignee?.email]}
            setSelectedItems={([assigneeEmail]) => {
              const selectedAssignee = sortedAssignees?.find(
                (b) => b.email === assigneeEmail,
              )
              setSelectedAssignee(selectedAssignee)
            }}
            showSearchBar={true}
          />

          <FormHelperText>Assignee to assign alerts to</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Notes</FormLabel>

          <Textarea
            minHeight="100px"
            onChange={(e) => setNotes(e.target.value)}
            placeholder={'Add notes here...'}
            value={notes}
          />

          <FormHelperText>
            Notes for assignee. Notes will be left as a comment on the alert
          </FormHelperText>
        </FormControl>
      </Stack>
    )
  }

  return (
    <DoppelModal
      body={renderModalBody()}
      isAsync={true}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
      primaryAction={updateAlert}
      primaryLabel="Assign alerts"
      size="lg"
      title={'Bulk Assign Alerts'}
    ></DoppelModal>
  )
}
