import React from 'react'
import { useDisclosure, Stack } from '@chakra-ui/react'
import DoppelDefaultButton from '../shared/doppel_default_button'
import BulkAssignAlertsModal from './bulk_assign_alerts_modal'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { IoPeople } from 'react-icons/io5'

export default function BulkAssignAlertsContainer({
  refreshFunc = () => {},
  selectedRows = null,
  showIcon = true,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isEmployeeView] = useIsEmployeeView()

  if (!isEmployeeView) {
    return <></>
  }
  return (
    <>
      <Stack direction="row" spacing={4}>
        <DoppelDefaultButton
          isInternal
          leftIcon={showIcon && <IoPeople size="12px" />}
          marginRight={4}
          onClick={onOpen}
        >
          Bulk Assign Alerts
        </DoppelDefaultButton>

        <BulkAssignAlertsModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
          selectedRows={selectedRows}
        />
      </Stack>
    </>
  )
}
